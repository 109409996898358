<template>
  <v-row align="center" class="mt-3 py-2" no-gutters>
    <v-col cols="12" sm="6" md="4" align-self="center">
      <v-row justify="center">
        <vue-speedometer
          :width="gaugeWidth"
          :height="gaugeWidth / 2 + 10"
          :needleHeightRatio="0.7"
          :value="getCategoryRisk('overall')"
          :segments="4"
          :maxValue="100"
          :customSegmentStops="[0, 15, 35, 58, 100]"
          :segmentColors="[
            $vuetify.theme.currentTheme.vitrueDarkGreen,
            $vuetify.theme.currentTheme.vitrueBrightGreen,
            $vuetify.theme.currentTheme.vitrueYellow,
            $vuetify.theme.currentTheme.vitrueRed
          ]"
          :ringWidth="gaugeWidth / 10"
          :needleTransitionDuration="3333"
          needleTransition="easeElastic"
          :needleColor="$vuetify.theme.currentTheme.primary"
          labelFontSize="0"
          forceRender
        />
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="8"
      class="px-3 text-h5"
      :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-16'"
    >
      <p v-if="$vuetify.breakpoint.sm">
        <span>{{ $t("burnoutReportSummary.title") }}</span>
      </p>
      <p>
        <span v-if="!$vuetify.breakpoint.sm">{{
          $t("burnoutReportSummary.title")
        }}</span>
        <span class="ml-6" :class="burnoutLevelStyle">{{
          getSeverityLevel("overall")
        }}</span>
      </p>
      <p :class="$vuetify.breakpoint.mdAndUp ? 'mt-8' : ''" id="summaryText">
        {{ getOverallText() }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import ProgressCard from "@/components/common/report/cards/ProgressCard";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import CategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues.json";
import BurnoutQuestions from "@/assets/json/BurnoutAssessment/BurnoutAssessmentQuestions.json";
import VueSpeedometer from "vue-speedometer";

export default {
  name: "SummaryContent",
  components: {
    ProgressCard,
    VueSpeedometer
  },
  props: {
    results: Object
  },
  data() {
    return {
      scores: BurnoutScores.getScores(this.results)
    };
  },
  watch: {
    results() {
      this.scores = BurnoutScores.getScores(this.results);
    }
  },
  computed: {
    categories() {
      let categories = Object.keys(this.scores);
      let overalIndex = categories.indexOf("overall");
      categories.splice(overalIndex, 1);
      return categories;
    },
    cardWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "90%";
      }
      return window.devicePixelRatio > 1.2 ? "180px" : "250px";
    },
    gaugeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 250;
        case "sm":
          return 275;
        case "md":
          return 300;
        case "lg":
          return 400;
        default:
          return 500;
      }
    },
    burnoutLevelStyle() {
      let color = this.getCategoryColor("overall") + "--text";
      let size = this.$vuetify.breakpoint.smAndUp ? "text-h3" : "text-h4";
      return color + " " + size;
    }
  },
  methods: {
    getCategoryRisk(category) {
      return BurnoutScores.getRiskPercentage(this.scores[category]);
    },
    getCategoryTitle(category) {
      let categoryQuestions = BurnoutQuestions.find(x => x.theme === category);
      return this.$t(categoryQuestions.title);
    },
    getCategoryText(category) {
      let color = this.getCategoryColor(category);
      let title = this.getCategoryTitle(category).toLowerCase();
      let level = this.getSeverityLevel(category);

      return this.$t("burnoutReportSummary.sentence", {
        0: color,
        1: level.toLowerCase(),
        2: title
      });
    },
    getOverallText() {
      let level = this.getSeverityLevel("overall").toLowerCase();
      return this.$t("burnoutReportSummary.overallSentence", {
        0: this.getCategoryRisk("overall") + "%",
        1: level
      });
    },
    getSeverityLevel(category) {
      let score = this.scores[category];
      if (score >= CategoryValues[category].veryhigh) {
        return this.$t("burnoutReportSummary.severity.veryHigh");
      } else if (score >= CategoryValues[category].high) {
        return this.$t("burnoutReportSummary.severity.high");
      } else if (score >= CategoryValues[category].average) {
        return this.$t("burnoutReportSummary.severity.average");
      } else {
        return this.$t("burnoutReportSummary.severity.low");
      }
    },
    getCategoryColor(category) {
      let score = this.scores[category];
      if (score >= CategoryValues[category].veryhigh) {
        return "vitrueRed";
      } else if (score >= CategoryValues[category].high) {
        return "vitrueYellow";
      } else if (score >= CategoryValues[category].average) {
        return "vitrueBrightGreen";
      } else {
        return "vitrueDarkGreen";
      }
    }
  }
};
</script>
