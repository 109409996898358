var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-3 py-2", attrs: { align: "center", "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", md: "4", "align-self": "center" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("vue-speedometer", {
                attrs: {
                  width: _vm.gaugeWidth,
                  height: _vm.gaugeWidth / 2 + 10,
                  needleHeightRatio: 0.7,
                  value: _vm.getCategoryRisk("overall"),
                  segments: 4,
                  maxValue: 100,
                  customSegmentStops: [0, 15, 35, 58, 100],
                  segmentColors: [
                    _vm.$vuetify.theme.currentTheme.vitrueDarkGreen,
                    _vm.$vuetify.theme.currentTheme.vitrueBrightGreen,
                    _vm.$vuetify.theme.currentTheme.vitrueYellow,
                    _vm.$vuetify.theme.currentTheme.vitrueRed
                  ],
                  ringWidth: _vm.gaugeWidth / 10,
                  needleTransitionDuration: 3333,
                  needleTransition: "easeElastic",
                  needleColor: _vm.$vuetify.theme.currentTheme.primary,
                  labelFontSize: "0",
                  forceRender: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "px-3 text-h5",
          class: _vm.$vuetify.breakpoint.smAndDown ? "" : "pl-16",
          attrs: { cols: "12", sm: "6", md: "8" }
        },
        [
          _vm.$vuetify.breakpoint.sm
            ? _c("p", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("burnoutReportSummary.title")))
                ])
              ])
            : _vm._e(),
          _c("p", [
            !_vm.$vuetify.breakpoint.sm
              ? _c("span", [
                  _vm._v(_vm._s(_vm.$t("burnoutReportSummary.title")))
                ])
              : _vm._e(),
            _c("span", { staticClass: "ml-6", class: _vm.burnoutLevelStyle }, [
              _vm._v(_vm._s(_vm.getSeverityLevel("overall")))
            ])
          ]),
          _c(
            "p",
            {
              class: _vm.$vuetify.breakpoint.mdAndUp ? "mt-8" : "",
              attrs: { id: "summaryText" }
            },
            [_vm._v(" " + _vm._s(_vm.getOverallText()) + " ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }